.side-drawer.left-slide nav {
  left: calc(-100%);
}
.side-drawer.left-slide-show nav {
  animation: left-slide-show-anim 0.5s forwards;
  -webkit-animation: left-slide-show-anim 0.5s forwards;  
}
.side-drawer.left-slide-hide nav {
  animation: left-slide-hide-anim 0.5s forwards;
  -webkit-animation: left-slide-hide-anim 0.5s forwards;  
}
@keyframes left-slide-show-anim {
  0% {
    transform: translateX(0%);
    -webkit-transform: translateX(0%);
  }
  100% {
    transform: translateX(100%);
    -webkit-transform: translateX(100%);
  }
}
@keyframes left-slide-hide-anim {
  0% {
    transform: translateX(100%);
    -webkit-transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
    -webkit-transform: translateX(0%);
  }
}
.side-drawer.right-slide nav {
  left: 100vw;
}
.side-drawer.right-slide-show nav {
  animation: right-slide-show-anim 0.5s forwards;
  -webkit-animation: right-slide-show-anim 0.5s forwards;  
}
.side-drawer.right-slide-hide nav {
  animation: right-slide-hide-anim 0.5s forwards;
  -webkit-animation: right-slide-hide-anim 0.5s forwards;  
}
@keyframes right-slide-show-anim {
  0% {
    transform: translateX(0%);
    -webkit-transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
  }
}
@keyframes right-slide-hide-anim {
  0% {
    transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
  }
  100% {
    transform: translateX(0%);
    -webkit-transform: translateX(0%);
  }
}
.side-drawer-children-enter {
  animation: 0.5s side-drawer-children-enter forwards;
}
.side-drawer-children-exit {
  animation: 0.5s side-drawer-children-exit forwards;
}
@keyframes side-drawer-children-enter {
  from {
    opacity: 0;
    /* transform: translate(-20px, 0); */
  }
  to {
    opacity: 1;
    /* transform: translate(0px, 0px); */
  }
}
@keyframes side-drawer-children-exit {
  from {
    opacity: 1;
    /* transform: translate(0px, 0px); */
  }
  to {
    /* transform: translate(-20px, 0); */
    opacity: 0;
  }
}