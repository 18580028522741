.cta-button {
  width: 200px;
}
.cta-button-text {
  width: 85%;
}
.cta-button img {
  transition: .8s
}
.cta-button:hover img {
  transform: scale(1.2)
}
.cta-button:hover img.-rotate-45 {
  transform: scale(1.2) rotate(-45deg)
}
.cta-button svg {
  margin-left: 0;
  transition: .8s
}
.cta-button:hover svg {
  margin-left: 5px;
}