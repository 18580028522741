.router-transition-enter {
  animation: 0.5s router-transition-enter forwards;
}
.router-transition-exit {
  animation: 0.5s router-transition-exit forwards;
}
@keyframes router-transition-enter {
  from {
    opacity: 0;
    transform: translate(-20px, 0);
  }
  to {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}
@keyframes router-transition-exit {
  from {
    opacity: 1;
    transform: translate(0px, 0px);
  }
  to {
    transform: translate(-20px, 0);
    opacity: 0;
  }
}