.portfolio-hover img {
  transition: .8s
}
.portfolio-hover:hover img {
  transform: scale(1.2)
}
.portfolio-hover:hover img.-rotate-45 {
  transform: scale(1.2) rotate(-45deg)
}
.portfolio-hover svg {
  position: absolute;
  left: calc(100%);
  transition: .8s
}
.portfolio-hover:hover svg {
  left: calc(105%)
}